import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { UIProductPricingGroup, } from 'pos/product-edit';
import { Area, IdName, Location, PortionSize, ProductUpdateRequest, ProductUpdateRequestPG, SalesChannel, SalesRestriction, StorageUnit } from 'utils/api';

@autoinject
export class ProductPriceRowsCustomElement {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) rows: UIProductPricingGroup[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) product!: ProductUpdateRequest;
	@bindable({ defaultBindingMode: bindingMode.toView }) client?: IdName;
	@bindable({ defaultBindingMode: bindingMode.toView }) editMode: boolean = false;
	@bindable({ defaultBindingMode: bindingMode.toView }) clientSpecific: boolean = true;
	@bindable({ defaultBindingMode: bindingMode.toView }) specify: boolean = false;
	@bindable({ defaultBindingMode: bindingMode.toView }) portionSizeList: PortionSize[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) salesChannelList: SalesChannel[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) salesRestrictionList: SalesRestriction[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) locationList: Location[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) areaList: Area[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) vatPercent: number | undefined;
	@bindable({ defaultBindingMode: bindingMode.toView }) portionSizes: { [key: string]: PortionSize; } = {};
	@bindable({ defaultBindingMode: bindingMode.toView }) salesChannels: { [key: string]: SalesChannel; } = {};
	@bindable({ defaultBindingMode: bindingMode.toView }) salesRestrictions: { [key: string]: SalesRestriction; } = {};
	@bindable({ defaultBindingMode: bindingMode.toView }) locations: { [key: string]: Location; } = {};
	@bindable({ defaultBindingMode: bindingMode.toView }) areas: { [key: string]: Area; } = {};
	@bindable({ defaultBindingMode: bindingMode.toView }) storageUnits: { [key: string]: StorageUnit; } = {};

	constructor() { }

	addRow() {
		let row = this.createNewRow();
		this.rows.push(row);
	}

	deleteRow(row: UIProductPricingGroup) {
		let newRows = this.rows.filter(r => r !== row);
		if (!newRows.length) {
			newRows.push(this.createNewRow());
		}
		this.rows = newRows;
	}

	createNewRow() {
		let rowRequest: ProductUpdateRequestPG = {
			clientId: this.client?.id,
			isHidden: false,
		};
		return new UIProductPricingGroup(
			this.product,
			rowRequest,
			this.vatPercent,
			this.portionSizes,
			this.salesChannels,
			this.salesRestrictions,
			this.locations,
			this.areas,
			this.storageUnits,
			this.areaList,
			false,
		);
	}

	@computedFrom("product.locationId")
	get dynamicLocationList() {
		return this.locationList.filter(x => !this.product.locationId || this.product.locationId == x.id);
	}
}
