import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { UIPricingGroupRow } from 'pos/pricing-group-edit';
import { Area, IdName, MyHttpApi, PortionSize, PricingGroupRowUpdateRequest, SalesChannel, SalesRestriction } from 'utils/api';

@autoinject
export class PricingGroupRowsCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) rows: UIPricingGroupRow[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) client?: IdName;
  @bindable({ defaultBindingMode: bindingMode.toView }) editMode: boolean = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) clientSpecific: boolean = true;
  @bindable({ defaultBindingMode: bindingMode.toView }) specify: boolean = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) portionSizeList: PortionSize[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) salesChannelList: SalesChannel[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) salesRestrictionList: SalesRestriction[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) locationList: Location[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) areaList: Area[] = [];

  constructor(private api: MyHttpApi) { }

  addRow() {
    let row = this.createNewRow();
    this.rows.push(row);
  }

  deleteRow(row: UIPricingGroupRow) {
    let newRows = this.rows.filter(r => r !== row);
    if (!newRows.length) {
      newRows.push(this.createNewRow());
    }
    this.rows = newRows;
  }

  createNewRow() {
    let rowRequest: PricingGroupRowUpdateRequest = {
      portionSizeId: "",
      clientId: this.client?.id,
      isHidden: false,
    };
    return new UIPricingGroupRow(rowRequest, this.areaList);
  }
}
